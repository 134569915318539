//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  // name: 'ComponentName',
  data: function data() {
    return {};
  },
  computed: {
    getGiftCoins: function getGiftCoins() {
      return this.$store.getters['player/giftCoins'];
    }
  },
  methods: {
    close: function close() {
      this.$store.dispatch('modals/setGiftPopup', {
        isOpen: false
      });
    }
  }
};